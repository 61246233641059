
import React from 'react';
import { Helmet } from "react-helmet";
import { websiteName } from '../utils/environment';
import { ChevronDoubleRightIcon, Bars3Icon, CheckCircleIcon, MinusCircleIcon, CalendarIcon } from '@heroicons/react/24/solid';
import '../index.css'; // Import the CSS file
import { useState } from 'react';
import { db } from '../utils/firebase';
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import posthog from 'posthog-js';
import { act } from 'react';



const LandingPage = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");

  // Track Signup Button Click
  const handleSignupButtonClick = () => {
    posthog.capture('SignupButtonClick', {
      action: 'modal_open',
    });
    document.getElementById('my_modal_3').showModal();
  };

  //this function will be called when the form is submitted
  //it will call the API to save the name and email to db
  //and then reset the form and close the sign up modal
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log("handleSubmit")
    //console.log(name, email, role);

    // Track Signup Form Submitted
    posthog.capture('SignupFormSubmitted', {
      email,
    });

    // Format the current date
    const date = new Date();
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/New_York", // Eastern Time
    });
    const formattedDateTime = `${formattedDate} ${formattedTime} ET`;
    //console.log(formattedDateTime);
    //console.log(window.location.origin);

    try {
      // Add data to Firestore with the date field
      const docRef = await addDoc(collection(db, "lyora_signups"), {
        name: name,
        email: email,
        role: role,
        date: formattedDateTime, // Add formatted date
        origin: window.location.origin, // Automatically add the current origin
      });
      //console.log("Document written with ID: ", docRef.id);
      // Reset the form
      setEmail("");
      setName("");
      setRole("");
      document.getElementById('my_modal_3').close(); //close the sign up modal
      //document.getElementById('my_modal_2').showModal(); //show the thank you modal
      navigate('/thankyou');


    } catch (error) {
      console.error("Error adding document: ", error);
      setError("Oops! Somehting went wrong. Please try again later.");
      //reset the form
      setEmail("");
      setName("");
      setRole("");
    }

  };



  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    const navbar = document.querySelector('nav'); // Assuming your navbar has a <nav> tag
    const offset = navbar ? navbar.offsetHeight : 64; // Fallback to 64 if navbar is not found
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    // Capture the event when a user clicks on a navbar link
    posthog.capture('NavbarLinkClicked', {  
      section: id,
    })

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };


  return (
    <>
      <Helmet>
        <title>{websiteName}</title>
      </Helmet>
      <div className="drawer drawer-end">
        <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col">
          {/* Navbar */}
          <div className="navbar bg-base-300 w-full fixed z-50">
            <a className="btn btn-ghost text-2xl" onClick={() => document.getElementById('top').scrollIntoView({ behavior: 'smooth' })}>{websiteName}</a>
            <div className="flex-1"></div>
            <div className="navbar-end">
              <div className="hidden flex-none lg:block">
                <ul className="menu menu-horizontal">
                  {/* Navbar menu content here */}
                  <li><a className='text-xl' onClick={() => scrollToElement('how')}>How it works?</a></li>
                  <li><a className='text-xl' onClick={() => scrollToElement('benefit')}>Benefits</a></li>
                  <li><a className='text-xl' onClick={() => scrollToElement('diff')}>Why we different?</a></li>
                  <li><a className='text-xl' onClick={() => scrollToElement('who')}>Who is it for?</a></li>
                  <li>
                    <button className="btn btn-primary rounded-full px-12" onClick={handleSignupButtonClick}>
                      Sign Up
                    </button>
                  </li>
                </ul>
              </div>
              <div className="flex-none lg:hidden">
                <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost">
                  <Bars3Icon className="w-7 h-7" />
                </label>
              </div>
            </div>
          </div>
          {/* Page content here */}

          {/* Top Hero section */}
          <div id="top" className="hero bg-base-300 min-h-[750px] text-center">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div>
                {/* <h1 className="text-5xl font-bold">Make Better Decisions with AI</h1> */}
                {/* <h1 className="text-5xl lg:text-6xl font-bold">Understand What Your Customers <span className="gradient-text">Want</span> with AI</h1> */}
                <h1 className="text-5xl lg:text-8xl font-bold">Turn Feedback Into Business <span className="gradient-text">Growth</span> With AI</h1>
                <p className="py-6 text-lg lg:text-xl">
                  Our AI-driven platform helps you turn your audience's feedback,&nbsp;
                  <a href="#diff" onClick={(e) => { e.preventDefault(); scrollToElement('diff'); }} className=" text-blue-500 hover:underline">
                    reviews<sup>*</sup>
                  </a>
                  , testimonials, suggestions, and other inputs into valuable insights to drive meaningful improvements and uncover new opportunities.
                </p>
                <button className="btn btn-primary rounded-full px-12 mt-4" onClick={handleSignupButtonClick}>
                  Sign Up
                  <ChevronDoubleRightIcon className="w-5 h-5" />
                </button>
                <p className="text-sm text-gray-600 mt-1">No credit card required.</p>
              </div>
            </div>
          </div>

          {/* How Hero section */}
          <div id="how" className="hero bg-base-100  lg:min-h-[2150px]">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div>
                {/* <h1 className="text-5xl font-bold">Make Better Decisions with AI</h1> */}
                <h1 className="text-4xl lg:text-5xl font-bold">How it works?</h1>
                <p className="py-6 text-lg lg:text-xl">
                  Our platform makes it simple: create and share a link, collect responses, and let AI turn them into actionable insights.
                </p>
                <div className="grid grid-cols-1  gap-8">
                  <div className="card bg-base-300 lg:card-side w-full shadow-xl">
                    <div className="card-body lg:w-96 px-4 py-4 lg:px-10 lg:py-10">
                      <h2 className="card-title text-2xl lg:text-3xl">#1 Create & Share Link</h2>
                      <p className="text-lg lg:text-xl">Create a feedback link and share it with your audience.</p>
                    </div>
                    <figure className="px-4 py-4 lg:px-10 lg:py-10">
                      <img
                        src="/assests/create-link-and-share.gif"
                        alt="Create and Share Feedback Link"
                        className="rounded-xl" />
                    </figure>
                  </div>
                  <div className="card bg-base-300 lg:card-side w-full shadow-xl" >
                    <div className="card-body lg:w-96 px-4 py-4 lg:px-10 lg:py-10">
                      <h2 className="card-title text-2xl lg:text-3xl">#2 Gather Inputs</h2>
                      <p className="text-xl" >
                        Collect responses as your audience shares feedback and suggestions.
                      </p>
                    </div>
                    <figure className="px-4 py-4 lg:px-10 lg:py-10">
                      <img
                        src="/assests/gatherinputs.gif"
                        alt="Create and Share Feedback Link"
                        className="rounded-xl" />
                    </figure>
                  </div>
                  <div className="card bg-base-300 lg:card-side w-full shadow-xl" >
                    <div className="card-body lg:w-96 px-4 py-4 lg:px-10 lg:py-10">
                      <h2 className="card-title text-2xl lg:text-3xl">#3 Analyze Insights</h2>
                      <p className="text-xl" >
                        Let AI identify trends and actionable recommendations.
                      </p>
                    </div>
                    <figure className="px-4 py-4 lg:px-10 lg:py-10">
                      <img
                        src="/assests/analyze.gif"
                        alt="Analyze Insights"
                        className="rounded-xl" />
                    </figure>
                  </div>
                </div>
              </div>

            </div>
          </div>


          {/* Benefits Hero section */}
          <div id="benefit" className="hero bg-base-200 min-h-[650px]">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div>
                {/* <h1 className="text-5xl font-bold">Make Better Decisions with AI</h1> */}
                <h1 className="text-4xl lg:text-5xl font-bold py-6">Benefits</h1>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div className="card bg-base-300 grid flex flex-1 flex-col shadow-xl">

                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 ">
                      <h2 className="card-title text-2xl lg:text-3xl mb-6">
                        Make Feedback Work for You
                      </h2>
                      <p className="text-lg  flex-1">Turn unstructured feedback into clear, actionable recommendations that drive growth.</p>
                    </div>
                  </div>
                  <div className="card bg-base-300 grid flex flex-col flex-1 shadow-xl">

                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 flex-grow">
                      <h2 className="card-title text-2xl lg:text-3xl mb-6">
                        Save Time & Money
                      </h2>
                      <p className="text-lg  flex-1">Let AI handle the analysis so you can focus on what matters most.</p>
                    </div>
                  </div>
                  <div className="card bg-base-300 grid flex flex-col flex-1 shadow-xl">

                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 flex-grow">
                      <h2 className="card-title text-2xl lg:text-3xl mb-6">
                        Understand your customers
                      </h2>
                      <p className="text-lg  flex-1">Understand why customers leave and take action to keep them coming back.</p>
                    </div>
                  </div>
                  <div className="card bg-base-300 grid flex flex-col flex-1 shadow-xl">

                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 flex-grow">
                      <h2 className="card-title text-2xl lg:text-3xl mb-6">
                        Make Better Decisions
                      </h2>
                      <p className="text-lg  flex-1">
                        Improve workflows and deliverables by gathering clear, actionable insights from clients or collaborators.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Diff Hero section */}
          <div id="diff" className="hero bg-base-100 min-h-[750px]">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div>
                {/* <h1 className="text-5xl font-bold">Make Better Decisions with AI</h1> */}
                <h1 className="text-4xl lg:text-5xl font-bold">More Than Just Reviews</h1>
                <p className="py-6 text-lg lg:text-xl">
                  Traditional
                  review platforms focus on attracting new customers — <strong><em>Lyora.io helps you keep them</em></strong> by turning private feedback into actionable insights with AI.
                </p>

                <div className="flex w-full flex-col lg:flex-row">
                  <div className="card bg-base-300 grid flex-1 shadow-xl">
                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10">
                      <h2 className="card-title text-2xl lg:text-3xl">lyora.io</h2>
                      <div className="flex items-center mt-8">
                        <CheckCircleIcon className="w-8 h-8 text-green-500 mr-2" />
                        <span>Private and targeted feedback.</span>
                      </div>
                      <div className="flex items-center mt-1">
                        <CheckCircleIcon className="w-8 h-8 text-green-500 mr-2" />
                        <span>Actionable insights powered by AI.</span>
                      </div>
                      <div className="flex items-center mt-1">
                        <CheckCircleIcon className="w-8 h-8 text-green-500 mr-2" />
                        <span>You control who provides feedback.</span>
                      </div>
                      <div className="flex items-center mt-1">
                        <CheckCircleIcon className="w-8 h-8 text-green-500 mr-2" />
                        <span>Suggested actions for improvement.</span>
                      </div>
                      {/* Card content here */}
                    </div>
                  </div>
                  <div className="divider lg:divider-horizontal">Vs</div>
                  <div className="card bg-base-300 grid flex-1 shadow-xl">
                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10">
                      <h2 className="card-title text-2xl lg:text-3xl">Yelp/Google Reviews</h2>
                      <div className="flex items-center mt-8">
                        <MinusCircleIcon className="w-8 h-8 text-gray-400 mr-2" />
                        <span>Publicly visible reviews.</span>
                      </div>
                      <div className="flex items-center mt-1">
                        <MinusCircleIcon className="w-8 h-8 text-gray-400 mr-2" />
                        <span>No advanced analysis—just raw reviews.</span>
                      </div>
                      <div className="flex items-center mt-1">
                        <MinusCircleIcon className="w-8 h-8 text-gray-400 mr-2" />
                        <span>Open for anyone to leave a review.</span>
                      </div>
                      <div className="flex items-center mt-1">
                        <MinusCircleIcon className="w-8 h-8 text-gray-400 mr-2" />
                        <span>None—analysis left to the business.</span>
                      </div>
                      {/* Card content here */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Who Hero section */}
          <div id="who" className="hero bg-base-200 min-h-[1050px]">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div>
                {/* <h1 className="text-5xl font-bold">Make Better Decisions with AI</h1> */}
                <h1 className="text-4xl lg:text-5xl font-bold">Who is lyora.io for?</h1>
                <p className="py-6 text-lg lg:text-xl">
                  Lyora.io is perfect for anyone looking to turn private feedback into actionable insights, whether you're organizing events, running a business, teaching, or freelancing.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div className="card bg-base-300 grid flex flex-1 flex-col shadow-xl">
                    <figure className="flex justify-center items-center">
                      <img
                        src="/assests/event01.png"
                        alt=" Event & Community Organizers Image"
                        className="object-contain w-full h-full"
                      />
                    </figure>
                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 flex-grow">
                      <h2 className="card-title text-2xl lg:text-3xl flex items-center mb-2">
                        Event & Community Organizers
                      </h2>
                      <p className="text-lg">Gather insights from attendees or community members to refine events, programs, and activities with actionable feedback.</p>
                    </div>
                  </div>
                  <div className="card bg-base-300 grid flex flex-col flex-1 shadow-xl">
                    <figure className="">
                      <img
                        src="/assests/localbusiness.png"
                        alt="Local Businesses & Professionals Image"
                        className="object-contain w-full h-full"
                      />
                    </figure>
                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 flex-grow">
                      <h2 className="card-title text-2xl lg:text-3xl flex items-center mb-2">
                        Local Businesses & Professionals
                      </h2>
                      <p className="text-lg">Understand customer needs to enhance offerings, build loyalty, and drive repeat business with private feedback.</p>
                    </div>
                  </div>
                  <div className="card bg-base-300 grid flex flex-col flex-1 shadow-xl">
                    <figure className="">
                      <img
                        src="/assests/Educators.png"
                        alt="Educators & Trainers Image"
                        className="object-contain w-full h-full"
                      />
                    </figure>
                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 flex-grow">
                      <h2 className="card-title text-2xl lg:text-3xl flex items-center mb-2">
                        Educators, Trainers & Coaches
                      </h2>
                      <p className="text-lg">Refine courses, workshops, or training programs by collecting actionable feedback from participants.</p>
                    </div>
                  </div>
                  <div className="card bg-base-300 grid flex flex-col flex-1 shadow-xl">
                    <figure className="">
                      <img
                        src="/assests/freelancer.png"
                        alt="Freelancers & Small Teams Image"
                        className="object-contain w-full h-full"
                      />
                    </figure>
                    <div className="card-body px-4 py-4 lg:px-10 lg:py-10 flex-grow">
                      <h2 className="card-title text-2xl lg:text-3xl flex items-center mb-2">
                        Freelancers, Creators & Small Teams
                      </h2>
                      <p className="text-lg">
                        Improve workflows and deliverables by gathering clear, actionable insights from clients or collaborators.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Page content end here */}
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay">          </label>
          <ul className="menu bg-base-200 min-h-full w-80 p-4 mt-16">
            {/* Sidebar content here */}
            <li><a className='text-xl' onClick={() => scrollToElement('how')}>How it works?</a></li>
            <li><a className='text-xl' onClick={() => scrollToElement('benefit')}>Benefits</a></li>
            <li><a className='text-xl' onClick={() => scrollToElement('diff')}>Why we different?</a></li>
            <li><a className='text-xl' onClick={() => scrollToElement('who')}>Who is it for?</a></li>
            <li>
              <button className="btn btn-primary rounded-full px-6 mt-4" onClick={() => document.getElementById('my_modal_3').showModal()}>
                Sign Up
                <ChevronDoubleRightIcon className="w-5 h-5" />
              </button>
            </li>

          </ul>
        </div>


        {/* Sign up modal with name and email */}
        <dialog id="my_modal_3" className="modal">
          <div className="modal-box">
            <form method="dialog">

              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form >
            <h3 className="font-bold text-lg">Sign Up</h3>
            <form onSubmit={handleFormSubmit} className="card-body">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Name</span>
                </label>
                <input
                  type="text"
                  placeholder="name"
                  className="input input-bordered"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input type="email"
                  placeholder="email"
                  className="input input-bordered"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">I'm Signing Up As?</span>
                </label>
                <select className="select select-bordered w-full"
                  required
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option></option>
                  <option>Event & Community Organizers</option>
                  <option>Local Businesses & Professionals</option>
                  <option>Educators, Trainers & Coaches</option>
                  <option>Freelancers, Creators & Small Teams</option>
                  <option>Other</option>
                </select>
              </div>
              <p className="text-xs text-red-500">{error}</p>
              <div className="form-control mt-6">
                <button className="btn btn-primary" >Sign up</button>
              </div>
            </form>
          </div>
        </dialog>

        {/* Thank you modal after successfull signup */}
        <dialog id="my_modal_2" className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Awesome!</h3>
            <p className="py-4">
              Thank you for signing up. We will be in touch soon.
            </p>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>


        <footer className="footer bg-base-300 text-base-content p-10">
          <nav>
            <h6 className="footer-title">Legal</h6>
            <Link to="/privacy-policy" className="link link-hover">Privacy policy</Link>
            <Link to="/terms-of-service" className="link link-hover">Terms of service</Link>
          </nav>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;