// App.js
import { BrowserRouter, MemoryRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect } from 'react';

import LandingPage from './components/LandingPage';
import NotFound404Page from './components/ErrorPages/NotFound404Page';
import ThankyouPage from './components/ThankyouPage';
import PrivacyPolicy from './components/Policies/privacyPolicy';
import TermsOfServicePolicy from './components/Policies/termsPolicy';
import { isProduction } from './utils/environment';
import posthog from 'posthog-js';
import KEYS from './config/keys';

const App = () => {

//initialize posthog
if (isProduction) {
  //console.log("Production, Initializing Posthog...3")
  posthog.init(KEYS.POSTHOG_API_KEY,
     {
       api_host: 'https://us.i.posthog.com',
       autocapture: true, // Disable automatic event capturing
       person_profiles:  'always', //identified_only' or 'always' to create profiles for anonymous users as well
     }
   )
 }


  return (
    <div>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/thankyou" element={<ThankyouPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfServicePolicy />} />
          <Route path="*"  element={<NotFound404Page/>}/>
        </Routes>

      </BrowserRouter>

    </div>
  );
};

export default App;
